import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";
import { cn } from "utils/utils.ts";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "focus-visible:ring-ring focus-visible:ring-offset-background peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-gray-950 transition-colors duration-150 ease-in-out focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:border-gray-300 data-[state=unchecked]:bg-white enabled:data-[state=checked]:border-green-600 enabled:data-[state=checked]:bg-green-600 enabled:hover:data-[state=checked]:border-green-700 enabled:hover:data-[state=checked]:bg-green-700 enabled:hover:data-[state=unchecked]:bg-gray-50 disabled:data-[state=checked]:bg-gray-300",
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block size-4 rounded-full shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-1 data-[state=checked]:bg-white data-[state=unchecked]:bg-gray-900 group-disabled/toggle:data-[state=unchecked]:bg-gray-300",
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
